import React, { useEffect, useRef, useState } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { API_BASE_URL } from '../../global';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import axios from 'axios';
import { FileUpload } from 'primereact/fileupload';
import { MultiSelect } from 'primereact/multiselect';
import './Coaches.scss';
        

function Coaches() {
  const toast = useRef(null);
  const [coaches, setCoaches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPasswordModalVisible, setShowPasswordModalVisible] = useState(false);
  const [showCategoriesModal, setShowCategoriesModal] = useState(false);
  const [showPasswords, setShowPasswords] = useState(false);
  const [adminPassword, setAdminPassword] = useState('');
  const [categories, setCategories] = useState([]);
  const [coach, setCoach] = useState(null);
  const [coachCategories, setCoachCategories] = useState([]);
  const [isCategoriesAdd, setIsCategoriesAdd] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState(null);
  const [categoriesOptions, setCategoriesOptions] = useState([]);

  useEffect(() => {
    fetchCoaches();
    fetchCategories();
  }, []);

  function fetchCategories () {
    axios
      .get(`${API_BASE_URL}/categories/`)
      .then((response) => {
        setCategories(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fetchCoaches() {
    setLoading(true);
    axios
      .get(`${API_BASE_URL}/coach/`)
      .then((response) => {
        setCoaches(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  const getSingleCoach = (id) => {
    axios
      .get(`${API_BASE_URL}/coach/${id}`)
      .then((response) => {
        setCoachCategories([]);
        setCategoriesOptions([]);
        setCoach(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    GetCoachCategories(coach);
  }, [coach]);

  function handleCoachDemote (id) {
    axios
      .put(`${API_BASE_URL}/coach/demote/${id}`)
      .then((response) => {
        toast.current.show({ severity: 'success', summary: 'نجحت العملية', detail: 'تم تخفيض الصلاحيات بنجاح' });
        fetchCoaches();
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: 'فشلت العملية', detail: 'حدث خطأ اثناء اتمام العملية' });
      });
  }


  function GetCoachCategories(coach) {
    if(Array.isArray(coach?.categoryId) && coach?.categoryId?.length === 0){
      setCoachCategories([]);
      setCategoriesOptions(categories);
      return;
    } 
    categories.map((category) => {
        if(coach?.categoryId?.includes(category?.id)){
          setCoachCategories((prev) => [...prev, category]);
        }else{
          setCategoriesOptions((prev) => [...prev, category]);
        }      
    });
  }


  function handleCoachCategoriesUpdate (id) {
    axios
      .put(`${API_BASE_URL}/coach/updateCategories/${id}`, { categoryId: coachCategories?.map((cat) => cat?.id) })
      .then((response) => {
        toast.current.show({ severity: 'success', summary: 'نجحت العملية', detail: 'تم تحديث الاقسام بنجاح' });
        fetchCoaches();
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: 'فشلت العملية', detail: 'حدث خطأ اثناء اتمام العملية' });
      });
  };

        



  // function handleResetPassword(id) {
  //   axios
  //     .put(`${API_BASE_URL}/coach/reset/${id}`)
  //     .then((resonse) => {
  //       confirmDialog({
  //         header: `كلمة المرور الجديدة هي ${resonse.data.password}`,
  //         headerClassName: 'gap-3',
  //         footer: () => (
  //           <Button
  //             label='نسخ و إغلاق'
  //             onClick={() => {
  //               navigator.clipboard.writeText(resonse.data.password);
  //               fetchCoaches();
  //               confirmDialog().hide();
  //             }}
  //           />
  //         ),
  //         draggable: false,
  //         resizable: false
  //       });
  //     })
  //     .catch((error) => {
  //       toast.current.show({ severity: 'error', summary: 'فشلت العملية', detail: 'حدث خطأ اثناء اتمام العملية' });
  //     });
  // }

  function verifyAdmin() {
    axios
      .post(`${API_BASE_URL}/auth/login`, { name: localStorage.getItem('admin-name'), password: adminPassword })
      .then((resonse) => {
        setShowPasswords(true);
        setAdminPassword('');
        setShowPasswordModalVisible(false);
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: 'فشلت العملية', detail: 'كلمة مرور خاطئة' });
      });
  }
    function handleFileUpload(e) {
    let csvfile = e.files[0];
    const formdata = new FormData();
    formdata.append('csvfile', csvfile);
    axios
      .post(`${API_BASE_URL}/coach/many`, formdata, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        toast.current.show({ severity: 'success', summary: 'نجحت العملية', detail: 'تم إضافة المستخدمين بنجاح' });
        // setTimeout(() => navigate('/coaches'), 1500);
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: 'فشلت العملية', detail: 'حدث خطأ اثناء اتمام العملية' });
      });
  }


  
  return (
    <div className='coaches'>
      <Toast ref={toast}></Toast>
      <ConfirmDialog />
      <Dialog
        header='قم بإدخال كلمة مرورك للتأكيد'
        headerClassName='gap-4'
        visible={showPasswordModalVisible}
        footer={<Button label='تأكيد' onClick={verifyAdmin} />}
        onHide={() => {
          setAdminPassword('');
          setShowPasswordModalVisible(false);
        }}
        draggable={false}
        resizable={false}
      >
        <InputText
          placeholder='كلمة مرورك الشخصية'
          value={adminPassword}
          onChange={(event) => setAdminPassword(event.target.value)}
        />
      </Dialog>
      <Dialog
        headerClassName='gap-4'
        visible={isCategoriesAdd}
        footer={<Button label='تأكيد' onClick={()=>{
          selectedCategories.map((category) => {
            setCoachCategories((prev) => [...prev, category]);
          }
          );
          setIsCategoriesAdd(false);
        }}  />}
        onHide={() => {
          setIsCategoriesAdd(false);
        }}
        draggable={false}
        resizable={false}
      >
        <MultiSelect value={selectedCategories} onChange={(e) => {
          setSelectedCategories(e.value);
        }} options={categoriesOptions} optionLabel="name_ar" 
                placeholder="اختر الاقسام"
                  className="w-full md:w-20rem" />
      </Dialog>
      <Dialog
        header="قم بتحديد الاقسام التي يمكن للمدرب الوصول اليها"
        headerClassName='gap-4'
        visible={showCategoriesModal}
        footer={<Button label='تأكيد' onClick={()=>{
          handleCoachCategoriesUpdate(coach.id);
          setShowCategoriesModal(false);
        }}/>}
        onHide={() => {
          setShowCategoriesModal(false);
        }}
        draggable={false}
        resizable={false}
      >
       {categories?.length !== coachCategories?.length ?
        <Button
          label="اضافة قسم جديد"
          severity="success"          
          outlined
          rounded
          style={{
            marginBottom: 10
          }}
          onClick={() => {
            setIsCategoriesAdd(true);
          }}
        />
        :<></>}
        
        <DataTable
              value={coachCategories}
              dataKey='id'
              className='w-full'
              size='small'
              stripedRows
              
            >
              <Column header='الاسم' field='name_ar' />
              <Column header='الاسم' field='name_en' />
              <Column header='الوصف' field='description_ar' />
              <Column header='الوصف' field='description_en' />
              <Column
                header='خيارات'
                body={(rowData) => (                  
                  <Button
                    label="حذف القسم"
                    severity="danger"
                    onClick={() => {
                      setCoachCategories(coachCategories.filter((cat) => cat.id !== rowData.id));
                    }}
                    outlined
                    rounded
                    style={{
                      marginRight:10
                    }}
                  />
                )}
                className=''
              />
            </DataTable>

        
      </Dialog>

      <div className='flex w-full p-7 flex-column'>
        {loading ? (
          <div className='flex justify-content-center'>
            <ProgressSpinner />
          </div>
        ) : (
          <>
            <div className='w-full flex justify-content-between pb-5'>
              <span className='text-4xl font-bold'>قائمة المدربين</span>
              <div className='flex gap-3'>
                {/* <Link to='/coaches/add'> */}
                  <Button disabled label='إضافة مدرب جديد' severity='success' raised />
                {/* </Link> */}
                {/* <Button label='إظهار كلمات المرور' onClick={() => setShowPasswordModalVisible(true)} outlined /> */}
                 {/* <FileUpload
            mode='basic'
            chooseLabel='تحميل ملف بيانات المدربين'
            accept='.csv'
            uploadHandler={handleFileUpload}
            customUpload
            className='add-coach__upload'
            chooseOptions={{
              icon: 'pi pi-fw pi-upload',
              className: 'custom-choose-btn p-button-outlined'
                    }}
                /> */}
              </div>
            </div>
            <DataTable
              value={coaches}
              paginator
              rows={5}
              rowsPerPageOptions={[5, 10, 25, 50]}
              dataKey='id'
              className='w-full'
              size='small'
              stripedRows
              onRowClick={(e) => console.log(e.data)}
            >
              <Column header='الاسم' field='name' />
              <Column header='البريد الالكتروني' field='email' />
              <Column header='رقم الهاتف' field='mobileNum' />
              <Column header='كود المستخدم' field='id' />
              <Column header='الفئة' field='categoryName' />
              {/* <Column
                header='كلمة المرور'
                body={(rowData) => <span>{showPasswords ? rowData.password : '********'}</span>}
              /> */}
              <Column
                header='خيارات'
                body={(rowData) => (
                  <div>
                  <Button
                    label="الارجاع الي موظف"
                    severity="info"
                    onClick={() => handleCoachDemote(rowData.id)}
                    outlined
                    rounded
                  />
                  <Button
                    label="تعديل الاقسام"
                    severity="info"
                    onClick={() => {
                      setSelectedCategories(null);
                      setShowCategoriesModal(true);
                      getSingleCoach(rowData.id);
                    }}
                    outlined
                    rounded
                    style={{
                      marginRight:10
                    }}
                  />
                  </div>
                )}
                className=''
              />
            </DataTable>
          </>
        )}
      </div>
    </div>
  );
}

export default Coaches;
