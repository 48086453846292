import React, { useEffect, useRef, useState } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { API_BASE_URL } from '../../global';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import axios from 'axios';
import './Employees.scss';
import { FileUpload } from 'primereact/fileupload';

function Employees() {
  const toast = useRef(null);
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPasswordModalVisible, setShowPasswordModalVisible] = useState(false);
  const [showPasswords, setShowPasswords] = useState(false);
  const [adminPassword, setAdminPassword] = useState('');

  useEffect(() => {
    fetchEmployees();
  }, []);

  function fetchEmployees() {
    setLoading(true);
    axios
      .get(`${API_BASE_URL}/employee/`)
      .then((response) => {
        setLoading(false);
        setEmployees(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  // function handleResetPassword(id) {
  //   axios
  //     .put(`${API_BASE_URL}/employee/reset/${id}`)
  //     .then((resonse) => {
  //       confirmDialog({
  //         header: `كلمة المرور الجديدة هي ${resonse.data.password}`,
  //         headerClassName: 'gap-3',
  //         footer: () => (
  //           <Button
  //             label='نسخ و إغلاق'
  //             onClick={() => {
  //               navigator.clipboard.writeText(resonse.data.password);
  //               fetchEmployees();
  //               confirmDialog().hide();
  //             }}
  //           />
  //         ),
  //         draggable: false,
  //         resizable: false
  //       });
  //     })
  //     .catch((error) => {
  //       toast.current.show({ severity: 'error', summary: 'فشلت العملية', detail: 'حدث خطأ اثناء اتمام العملية' });
  //     });
  // }
 function handlePromotion (id) {
    axios
      .put(`${API_BASE_URL}/employee/promote/${id}`)
      .then((resonse) => {
        toast.current.show({ severity: 'success', summary: 'نجحت العملية', detail: 'تم ترقية الموظف بنجاح' });
        fetchEmployees();
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: 'فشلت العملية', detail: 'حدث خطأ اثناء اتمام العملية' });
      });
 }
  function verifyAdmin() {
    axios
      .post(`${API_BASE_URL}/auth/login`, { name: localStorage.getItem('admin-name'), password: adminPassword })
      .then((resonse) => {
        setShowPasswords(true);
        setAdminPassword('');
        setShowPasswordModalVisible(false);
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: 'فشلت العملية', detail: 'كلمة مرور خاطئة' });
      });
  }
   function handleFileUpload(e) {
    let csvfile = e.files[0];
    const formdata = new FormData();
    formdata.append('csvfile', csvfile);
    axios
      .post(`${API_BASE_URL}/employee/many`, formdata, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        toast.current.show({ severity: 'success', summary: 'نجحت العملية', detail: 'تم إضافة المستخدمين بنجاح' });
        // setTimeout(() => navigate('/employees'), 1500);
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: 'فشلت العملية', detail: 'حدث خطأ اثناء اتمام العملية' });
      });
  }



  return (
    <div className='employees'>
      <Toast ref={toast}></Toast>
      <ConfirmDialog />
      <Dialog
        header='قم بإدخال كلمة مرورك للتأكيد'
        headerClassName='gap-4'
        visible={showPasswordModalVisible}
        footer={<Button label='تأكيد' onClick={verifyAdmin} />}
        onHide={() => {
          setAdminPassword('');
          setShowPasswordModalVisible(false);
        }}
        draggable={false}
        resizable={false}
      >
        <InputText
          placeholder='كلمة مرورك الشخصية'
          value={adminPassword}
          onChange={(event) => setAdminPassword(event.target.value)}
        />
      </Dialog>

      <div className='flex w-full p-7 flex-column'>
        {loading ? (
          <div className='flex justify-content-center'>
            <ProgressSpinner />
          </div>
        ) : (
          <>
            <div className='w-full flex justify-content-between pb-5'>
              <span className='text-4xl font-bold'>قائمة الموظفين</span>
              <div className='flex gap-3'>
                {/* <Link to='/employees/add'> */}
                  <Button disabled  label='إضافة موظف جديد' severity='success' raised />
                {/* </Link> */}
                {/* <Button label='إظهار كلمات المرور' onClick={() => setShowPasswordModalVisible(true)} outlined /> */}
                 {/* <FileUpload
          mode='basic'
          chooseLabel='تحميل ملف بيانات الموظفين'
          accept='.csv'
          uploadHandler={handleFileUpload}
          customUpload
          className='add-employee__upload'
          chooseOptions={{
            icon: 'pi pi-fw pi-upload',
            className: 'custom-choose-btn p-button-outlined'
          }}
        /> */}
              </div>
            </div>
            <DataTable
              value={employees}
              paginator
              rows={5}
              rowsPerPageOptions={[5, 10, 25, 50]}
              dataKey='id'
              className='w-full'
              size='small'
              stripedRows
            >
              <Column header='الاسم' field='name' />
              <Column header='البريد الالكتروني' field='email' sortable />
              <Column header='رقم الهاتف' field='mobileNum' sortable />
              <Column header='كود المستخدم' field='id' sortable />
           
              {/* <Column
                header='كلمة المرور'
                body={(rowData) => <span>{showPasswords ? rowData.password : '********'}</span>}
              /> */}
              <Column
                header='خيارات'
                body={(rowData) => (
                  <Button
                    label='ترقيه الي مدرب'
                    severity='info'
                    onClick={() => handlePromotion(rowData.id)}
                    outlined
                    rounded
                    style={{
                      marginRight:10
                    }}
                  />
                )}
                className=''
              />
             
            </DataTable>
          </>
        )}
      </div>
    </div>
  );
}

export default Employees;
