import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../global';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import './AddCategory.scss';
import { FileUpload } from 'primereact/fileupload';

function AddCategory() {
  const toast = useRef(null);
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [newCategory, setNewCategory] = useState({
    name_ar: '',
    name_en: '',
    description_ar: '',
    description_en: '',
  });

  function handleAddInputChange(e) {
    setNewCategory({ ...newCategory, [e.target.name]: e.target.value });
  }



  function handleAddNewCategory(e) {
    if (!newCategory.name_ar) {
      setErrorMsg('برجاء ادخال الاسم');
      setShowError(true);
      return;
    } else {
      setShowError(false);
      setErrorMsg('');
    }
    const formData = new FormData();
    formData.append('name_ar', newCategory.name_ar);
    formData.append('name_en', newCategory.name_en);
    formData.append('description_ar', newCategory.description_ar);
    formData.append('description_en', newCategory.description_en);
    formData.append('image', newCategory.image);
    axios
      .post(`${API_BASE_URL}/categories/single`, formData)
      .then((response) => {
        toast.current.show({ severity: 'success', summary: 'نجحت العملية', detail: 'تم إضافة القسم بنجاح' });
        setTimeout(() => navigate('/categories'), 1500);
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: 'فشلت العملية', detail: 'حدث خطأ اثناء اتمام العملية' });
      });
  }
  function onUpload(event) {
    setNewCategory({ ...newCategory, image: event.target.files[0] });
  }

 

  return (
    <div className='add-employee'>
      <Toast ref={toast}></Toast>
      <div className='flex justify-content-center align-items-center p-4 gap-5'>
        <div className='flex gap-4'>
          {showError ? <span className='login__form__error mb-3'>{errorMsg}</span> : <></>}
          <div className='flex flex-column gap-4'>
            <span>الاسم</span>
            <InputText name='name_ar' value={newCategory.name_ar} onChange={handleAddInputChange} />
            <span>الاسم انجليزي</span>
            <InputText name='name_en' value={newCategory.name_en} onChange={handleAddInputChange} />
            <span>الصوره</span>
            <input type='file' name='image' accept='image/*' onChange={onUpload} />
          </div>
          <div className='flex flex-column gap-4'>
            <span>الوصف</span>
            <InputText name='description_ar' value={newCategory.description_ar} onChange={handleAddInputChange} />
            <span>الوصف انجليزي</span>
            <InputText name='description_en' value={newCategory.description_en} onChange={handleAddInputChange} />
            <Button label='إضافة قسم جديد' onClick={handleAddNewCategory} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCategory;
