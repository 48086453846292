import React, { useEffect, useState } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Link, useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../global';
import axios from 'axios';
import './Login.scss';

function Login() {
  const [credentials, setCredentials] = useState({ name: '', password: '' });
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const navigate = useNavigate();

  function handleInputChange(event) {
    setCredentials({ ...credentials, [event.target.name]: event.target.value });
  }

  function handleLogin(event) {
    event.preventDefault();
    if (!credentials.name || !credentials.password) {
      setShowError(true);
      setErrorMsg('برجاء ادخال اسم المستخدم وكلمة المرور');
      return;
    } else {
      setShowError(false);
      setErrorMsg('');
    }
    setLoading(true);
    axios
      .post(`${API_BASE_URL}/auth/login`, credentials)
      .then((response) => {
        navigate('/');
        localStorage.setItem('admin-name', credentials.name);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        setShowError(true);
        setErrorMsg('هناك خطأ في اسم المستخدم او كلمة السر');
      });
  }

  return (
    <main className='login flex'>
      <form className='login__form' onSubmit={handleLogin}>
        <span className='login__form__header'>تسجيل الدخول</span>
        {showError ? <span className='login__form__error mb-3'>{errorMsg}</span> : <></>}

        <div>
          <input
            type='text'
            placeholder='اسم المستخدم'
            name='name'
            autoComplete='true'
            className='login__form__input'
            onChange={handleInputChange}
          />
        </div>
        <div>
          <input
            type='password'
            placeholder='كلمة السر'
            name='password'
            autoComplete='true'
            className='login__form__input'
            onChange={handleInputChange}
          />
        </div>

        {loading ? (
          <ProgressSpinner />
        ) : (
          <button type='submit' className='login__form__btn'>
            سجل الدخول
          </button>
        )}
      </form>
    </main>
  );
}

export default Login;
