import React, { useEffect, useRef, useState } from 'react';
import { API_BASE_URL } from '../../global';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import './Settings.scss';

function Settings() {
  const toast = useRef(null);
  const [settings, setSettings] = useState({ work_day_first_hour: '', work_day_last_hour: '' });

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/settings/`)
      .then((response) => {
        setSettings(response.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function handleSaveSettings(e) {
    axios
      .post(`${API_BASE_URL}/settings/`, settings)
      .then((response) => {
        toast.current.show({ severity: 'success', summary: 'نجحت العملية', detail: 'تم تعديل الاعدادات بنجاح' });
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: 'فشلت العملية', detail: 'حدث خطأ اثناء اتمام العملية' });
        console.log(error);
      });
  }

  return (
    <div className='settings'>
      <Toast ref={toast} />
      <div className='flex justify-content-center align-items-center gap-2'>
        <InputNumber
          name='work_day_first_hour'
          value={settings.work_day_first_hour}
          onChange={(e) => setSettings({ ...settings, work_day_first_hour: e.value })}
        />
        <label htmlFor='work_day_first_hour'>ساعة بداية العمل اليومي</label>
      </div>
      <div className='flex justify-content-center align-items-center gap-2'>
        <InputNumber
          name='work_day_last_hour'
          value={settings.work_day_last_hour}
          onChange={(e) => setSettings({ ...settings, work_day_last_hour: e.value })}
        />
        <label htmlFor='work_day_last_hour'>ساعة نهاية العمل اليومي</label>
      </div>
      <Button label='حفظ التعديلات' className='w-1' onClick={handleSaveSettings} />
    </div>
  );
}

export default Settings;
