import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'primereact/card';
import { MultiSelect } from 'primereact/multiselect';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import { TabView, TabPanel } from 'primereact/tabview';
import { API_BASE_URL } from '../../global';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Chart } from 'primereact/chart';
import axios from 'axios';
import './Statistics.scss';

function Statistics() {
  const toast = useRef(null);
  const [overview, setOverview] = useState({});
  const [chartData, setChartData] = useState({});
  const [userReport, setUserReport] = useState([]);
  const [rolesFilterSelect, setRolesFilterSelect] = useState([]);
  const [categoriesFilterSelect, setCategoriesFilterSelect] = useState([]);
  const [sessionStatusFilterSelect, setSessionStatusFilterSelect] = useState([]);
  const [sessionReport, setSessionReport] = useState([]);
  const [loading, setLoading] = useState(false);
  const chartOptions = {
    plugins: {
      legend: {
        labels: {
          usePointStyle: true
        }
      }
    }
  };
  const filtersUserReport = {
    role: { value: null, matchMode: FilterMatchMode.IN },
    categoryName: { value: null, matchMode: FilterMatchMode.IN }
  };
  const filtersSessionReport = {
    sessionStatus: { value: null, matchMode: FilterMatchMode.IN }
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_BASE_URL}/statistics/overview`)
      .then((response) => {
        setLoading(false);
        setOverview(response.data);
        setChartData({
          labels: [
            'عدد الجلسات التدريبية الناجحة',
            'عدد الجلسات التدريبية الغير ناجحة',
            'عدد الجلسات التدريبية الملغاة'
          ],
          datasets: [
            {
              data: [
                response.data.noOfSuccessfulSessions,
                response.data.noOfUnSuccessfulSessions,
                response.data.noOfCancelledSessions
              ]
            }
          ]
        });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
    axios
      .get(`${API_BASE_URL}/statistics/user-report`)
      .then((response) => {
        setUserReport(response.data);
        setRolesFilterSelect([...new Set(response.data.map((user) => user.role))]);
        setCategoriesFilterSelect([...new Set(response.data.map((user) => user.categoryName))]);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
    axios
      .get(`${API_BASE_URL}/statistics/session-report`)
      .then((response) => {
        setSessionReport(response.data);
        setSessionStatusFilterSelect([...new Set(response.data.map((session) => session.sessionStatus))]);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  const rolesFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={rolesFilterSelect}
        onChange={(e) => options.filterCallback(e.value)}
        placeholder='Any'
        className='p-column-filter'
      />
    );
  };

  const categoriesFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={categoriesFilterSelect}
        onChange={(e) => options.filterCallback(e.value)}
        placeholder='Any'
        className='p-column-filter'
      />
    );
  };

  const sessionStatusFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={sessionStatusFilterSelect}
        onChange={(e) => options.filterCallback(e.value)}
        placeholder='Any'
        className='p-column-filter'
      />
    );
  };

  return (
    <div className='statistics'>
      <TabView>
        <TabPanel header='Overview Dashboard'>
          {loading ? (
            <ProgressSpinner />
          ) : (
            <div className='statistics__container'>
              <div className='flex flex-column gap-4'>
                <div className='flex gap-2'>
                  <Card className='w-20rem text-right' title='عدد المدربين'>
                    {overview.noOfCoaches}
                  </Card>
                  <Card className='w-20rem text-right' title='عدد المتدربين'>
                    {overview.noOfEmployees}
                  </Card>
                </div>
                <div className='flex gap-2'>
                  <Card className='w-20rem text-right' title='عدد الجلسات التدريبية الناجحة'>
                    {overview.noOfSuccessfulSessions}
                  </Card>
                  <Card className='w-20rem text-right' title='عدد الجلسات التدريبية المنتظرة'>
                    {overview.noOfUpcomingSessions}
                  </Card>
                </div>
              </div>
              <div>
                <Card className='text-right' title='ارقام عن الجلسات التدريبية'>
                  <Chart type='pie' data={chartData} options={chartOptions} className='w-30rem' />
                  <div className='flex flex-column mt-5'>
                    <span className='text-xl'>عدد الجلسات التدريبية الناجحة: اللون الازرق</span>
                    <span className='text-xl'>عدد الجلسات التدريبية الغير ناجحة: اللون الاحمر</span>
                    <span className='text-xl'>عدد الجلسات التدريبية الملغاة: اللون البرتقالي</span>
                  </div>
                </Card>
              </div>
            </div>
          )}
        </TabPanel>
        <TabPanel header='User Report'>
          <div className='flex flex-column'>
            <DataTable
              value={userReport}
              paginator
              rows={5}
              rowsPerPageOptions={[5, 10, 25, 50]}
              filters={filtersUserReport}
              dataKey='id'
              className='w-full'
              size='small'
            >
              <Column header='الاسم' field='name' />
              <Column header='الايميل' field='email' />
              <Column
                header='الدور'
                field='role'
                filter
                filterField='role'
                showFilterMenuOptions={false}
                filterElement={rolesFilterTemplate}
              />
              <Column
                header='الفئة'
                field='categoryName'
                filter
                filterField='categoryName'
                showFilterMenuOptions={false}
                filterElement={categoriesFilterTemplate}
              />
              <Column header='عدد جلسات تدريبية تم حضورها' field='takenSessions' />
              <Column header='عدد جلسات تدريبية منتظر حضورها' field='upcomingSessions' />
            </DataTable>
          </div>
        </TabPanel>
        <TabPanel header='Session Report'>
          <div className='flex flex-column'>
            <DataTable
              value={sessionReport}
              paginator
              rows={5}
              rowsPerPageOptions={[5, 10, 25, 50]}
              filters={filtersSessionReport}
              dataKey='id'
              className='w-full'
              size='small'
            >
              <Column header='تاريخ الجلسة' field='sessionDate' />
              <Column header='اسم المدرب' field='coach_name' />
              <Column header='اسم المتدرب' field='employee_name' />
              <Column
                header='حالة الجلسة'
                field='sessionStatus'
                filter
                filterField='sessionStatus'
                showFilterMenuOptions={false}
                filterElement={sessionStatusFilterTemplate}
              />
              <Column header='تقييم المتدرب للجلسة' field='employee_total_rating' />
              <Column header='تقييم المدرب للجلسة' field='coach_total_rating' />
            </DataTable>
          </div>
        </TabPanel>
      </TabView>
    </div>
  );
}

export default Statistics;
