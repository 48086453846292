import React from 'react';
import './Home.scss';

function Home() {
  return (
    <main className='flex align-items-center justify-content-center p-8'>
      <h1>Welcome Admin</h1>
    </main>
  );
}

export default Home;
