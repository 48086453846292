import React, { useEffect, useRef, useState } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { API_BASE_URL } from '../../global';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import axios from 'axios';
import './Categories.scss';
import { Link } from 'react-router-dom';

function Categories() {
  const toast = useRef(null);
  const [categories, SetCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPasswordModalVisible, setShowPasswordModalVisible] = useState(false);
  const [adminPassword, setAdminPassword] = useState('');

  useEffect(() => {
    fetchEmployees();
  }, []);

  function fetchEmployees() {
    setLoading(true);
    axios
      .get(`${API_BASE_URL}/categories/`)
      .then((response) => {
        setLoading(false);
        SetCategories(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }



  function verifyAdmin() {
    axios
      .post(`${API_BASE_URL}/auth/login`, { name: localStorage.getItem('admin-name'), password: adminPassword })
      .then((resonse) => {
        setAdminPassword('');
        setShowPasswordModalVisible(false);
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: 'فشلت العملية', detail: 'كلمة مرور خاطئة' });
      });
  }
 


  return (
    <div className='employees'>
      <Toast ref={toast}></Toast>
      <ConfirmDialog />
      <Dialog
        header='قم بإدخال كلمة مرورك للتأكيد'
        headerClassName='gap-4'
        visible={showPasswordModalVisible}
        footer={<Button label='تأكيد' onClick={verifyAdmin} />}
        onHide={() => {
          setAdminPassword('');
          setShowPasswordModalVisible(false);
        }}
        draggable={false}
        resizable={false}
      >
        <InputText
          placeholder='كلمة مرورك الشخصية'
          value={adminPassword}
          onChange={(event) => setAdminPassword(event.target.value)}
        />
      </Dialog>

      <div className='flex w-full p-7 flex-column'>
        {loading ? (
          <div className='flex justify-content-center'>
            <ProgressSpinner />
          </div>
        ) : (
          <>
            <div className='w-full flex justify-content-between pb-5'>
              <span className='text-4xl font-bold'>قائمة الاقسام</span>
              <div className='flex gap-3'>
                <Link to='/categories/add'>
                  <Button label='إضافة قسم جديد' severity='success' raised />
                </Link>
              </div>
            </div>
            <DataTable
              value={categories}
              paginator
              rows={5}
              rowsPerPageOptions={[5, 10, 25, 50]}
              dataKey='id'
              className='w-full'
              size='small'
              stripedRows
            >
              <Column header= 'الرقم' field='id' />
               <Column header='الاسم' field='name_ar' />
               <Column header='الاسم انجليزي' field='name_en'  />
               <Column header='الوصف' field='description_ar' />
               <Column header='الوصف انجليزي' field='description_en' />
            <Column
                header='خيارات'
                body={(rowData) => (
                  <Button
                    label='ازالة'
                    severity='danger'
                    onClick={() => {
                      axios
                        .delete(`${API_BASE_URL}/categories/${rowData.id}`)
                        .then((response) => {
                          toast.current.show({ severity: 'success', summary: 'نجحت العملية', detail: 'تم حذف القسم بنجاح' });
                          fetchEmployees();
                        })
                        .catch((error) => {
                          toast.current.show({ severity: 'error', summary: 'فشلت العملية', detail: 'حدث خطأ اثناء اتمام العملية' });
                        });
                    }}
                    outlined
                    rounded
                  />
                )}
                className=''
              />
            </DataTable>
          </>
        )}
      </div>
    </div>
  );
}

export default Categories;
