import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import Navbar from './components/Navbar/Navbar';
import Employees from './pages/Employees/Employees';
import Coaches from './pages/Coaches/Coaches';
import Statistics from './pages/Statistics/Statistics';
import './style/reset.scss';
import Settings from './pages/Settings/Settings';
import AddCoach from './pages/Coaches/AddCoach';
import AddEmployee from './pages/Employees/AddEmployee';
import Categories from './pages/Categories/Categories';
import AddCategory from './pages/Categories/AddCategory';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/login' element={<Login />}></Route>
        <Route
          exact
          path='/'
          element={
            <ProtectedRoute>
              <Navbar />
              <Home />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          exact
          path='/coaches'
          element={
            <ProtectedRoute>
              <Navbar />
              <Coaches />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          exact
          path='/coaches/add'
          element={
            <ProtectedRoute>
              <Navbar />
              <AddCoach />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          exact
          path='/employees'
          element={
            <ProtectedRoute>
              <Navbar />
              <Employees />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          exact
          path='/categories'
          element={
            <ProtectedRoute>
              <Navbar />
              <Categories />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          exact
          path='/categories/add'
          element={
            <ProtectedRoute>
              <Navbar />
              <AddCategory />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          exact
          path='/employees/add'
          element={
            <ProtectedRoute>
              <Navbar />
              <AddEmployee />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          exact
          path='/statistics'
          element={
            <ProtectedRoute>
              <Navbar />
              <Statistics />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          exact
          path='/settings'
          element={
            <ProtectedRoute>
              <Navbar />
              <Settings />
            </ProtectedRoute>
          }
        ></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
