import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { API_BASE_URL } from '../../global';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import { Toast } from 'primereact/toast';
import axios from 'axios';
import { Dropdown } from 'primereact/dropdown';
import './AddCoach.scss';

function AddCoach() {
  const toast = useRef(null);
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [newCoach, setNewCoach] = useState({
    email: '',
    name: '',
    code: '',
    mobileNum: '',
    password: '',
    categoryId: ''
  });

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_BASE_URL}/categories/`)
      .then((response) => {
        setLoading(false);
        setCategories(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  function handleAddInputChange(e) {
    setNewCoach({ ...newCoach, [e.target.name]: e.target.value });
  }

  function handleCategoryChange(e) {
    setSelectedCategory(e.value);
    setNewCoach({ ...newCoach, categoryId: e.value });
  }

  // function generateRandomPassword() {
  //   const newPassword = Math.random().toString(36).slice(-8);
  //   setNewCoach({ ...newCoach, password: newPassword });
  // }

  function handleAddNewCoach(e) {
    if (Object.values(newCoach).filter((item) => !item.length).length) {
      setShowError(true);
      setErrorMsg('برجاء ادخال البيانات المطلوبة');
      return;
    } else {
      setShowError(false);
      setErrorMsg('');
    }

    axios
      .post(`${API_BASE_URL}/coach/single`, newCoach)
      .then((response) => {
        toast.current.show({ severity: 'success', summary: 'نجحت العملية', detail: 'تم إضافة المستخدم بنجاح' });
        setTimeout(() => navigate('/coaches'), 1500);
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: 'فشلت العملية', detail: 'حدث خطأ اثناء اتمام العملية' });
      });
  }



  return (
    <div className='add-coach'>
      <Toast ref={toast}></Toast>
      {loading ? (
        <ProgressSpinner />
      ) : (
        <div className='flex justify-content-center align-items-center p-4 gap-5'>
          <div className='flex gap-4'>
            {showError ? <span className='login__form__error mb-3'>{errorMsg}</span> : <></>}
            <div className='flex flex-column gap-4'>
              <span>الاسم</span>
              <InputText name='name' value={newCoach.name} onChange={handleAddInputChange} />
              <span>البريد الالكتروني</span>
              <InputText name='email' value={newCoach.email} onChange={handleAddInputChange} />
              <span>كود المستخدم</span>
              <InputText name='code' value={newCoach.code} onChange={handleAddInputChange} />
            </div>
            <div className='flex flex-column gap-4'>
              <span>رقم الهاتف</span>
              <InputText name='mobileNum' value={newCoach.mobileNum} onChange={handleAddInputChange} />
              {/* <span>كلمة المرور</span>
              <div className='p-inputgroup flex-1 add-coach__passwordgen'>
                <Button label='توليد كلمة مرور' onClick={generateRandomPassword} />
                <InputText name='password' value={newCoach.password} disabled />
              </div> */}
              <span>فئة المدرب</span>
              <Dropdown
                value={selectedCategory}
                options={categories}
                onChange={handleCategoryChange}
                optionLabel='name'
                optionValue='id'
              />
              <Button label='إضافة مدرب جديد' onClick={handleAddNewCoach} />
            </div>
          </div>

         
        </div>
      )}
    </div>
  );
}

export default AddCoach;
