import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../global';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import './AddEmployee.scss';

function AddEmployee() {
  const toast = useRef(null);
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [newEmployee, setNewEmployee] = useState({
    name: '',
    email: '',
    code: '',
    mobileNum: '',
    // password: ''
  });

  function handleAddInputChange(e) {
    setNewEmployee({ ...newEmployee, [e.target.name]: e.target.value });
  }

  // function generateRandomPassword() {
  //   const newPassword = Math.random().toString(36).slice(-8);
  //   setNewEmployee({ ...newEmployee, password: newPassword });
  // }

  function handleAddNewEmployee(e) {
    if (!newEmployee.email) {
      setShowError(true);
      setErrorMsg('برجاء ادخال البريد الالكتروني وكلمة المرور');
      return;
    } else {
      setShowError(false);
      setErrorMsg('');
    }

    axios
      .post(`${API_BASE_URL}/employee/single`, newEmployee)
      .then((response) => {
        toast.current.show({ severity: 'success', summary: 'نجحت العملية', detail: 'تم إضافة المستخدم بنجاح' });
        setTimeout(() => navigate('/employees'), 1500);
      })
      .catch((error) => {
        toast.current.show({ severity: 'error', summary: 'فشلت العملية', detail: 'حدث خطأ اثناء اتمام العملية' });
      });
  }

 

  return (
    <div className='add-employee'>
      <Toast ref={toast}></Toast>
      <div className='flex justify-content-center align-items-center p-4 gap-5'>
        <div className='flex gap-4'>
          {showError ? <span className='login__form__error mb-3'>{errorMsg}</span> : <></>}
          <div className='flex flex-column gap-4'>
            <span>الاسم</span>
            <InputText name='name' value={newEmployee.name} onChange={handleAddInputChange} />
            <span>البريد الالكتروني</span>
            <InputText name='email' value={newEmployee.email} onChange={handleAddInputChange} />
            {/* <span>كلمة المرور</span>
            <div className='p-inputgroup flex-1 add-employee__passwordgen'>
              <Button label='توليد كلمة مرور' onClick={generateRandomPassword} />
              <InputText name='password' value={newEmployee.password} disabled />
            </div> */}
          </div>
          <div className='flex flex-column gap-4'>
            <span>كود المستخدم</span>
            <InputText name='code' value={newEmployee.code} onChange={handleAddInputChange} />
            <span>رقم الهاتف</span>
            <InputText name='mobileNum' value={newEmployee.mobileNum} onChange={handleAddInputChange} />
            <Button label='إضافة موظف جديد' onClick={handleAddNewEmployee} />
          </div>
        </div>

      
      </div>
    </div>
  );
}

export default AddEmployee;
