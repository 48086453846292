import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { TabMenu } from 'primereact/tabmenu';

import './Navbar.scss';

function Navbar() {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(-1);

  const items = [
    {
      label: 'احصائيات وارقام',
      icon: 'pi pi-chart-line ml-2',
      command: () => navigate('/statistics')
    },
    {
      label: 'المدربين',
      icon: 'pi pi-users ml-2',
      command: () => navigate('/coaches')
    },
    {
      label: 'الموظفين',
      icon: 'pi pi-users ml-2',
      command: () => navigate('/employees')
    },
    {
      label: 'الاقسام',
      icon: 'pi pi-users ml-2',
      command: () => navigate('/categories')
    },
    {
      label: 'الاعدادات',
      icon: 'pi pi-cog ml-2',
      command: () => navigate('/settings')
    },
  ];

  function handleLogout(e) {
    localStorage.clear();
    navigate('/')
  }

  return (
    <main className='nav'>
      <Link to='/' className='flex align-items-center' onClick={() => setActiveIndex(-1)}>
        <span className='nav__logo text-2xl'>الصفحة الرئيسية</span>
      </Link>
      <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
      <div className='nav__icons'>
        <div className='nav__icons__btns'>
          <button onClick={handleLogout} className='flex'>
            <i className='pi pi-sign-out text-2xl' />
          </button>
        </div>
      </div>
    </main>
  );
}

export default Navbar;
